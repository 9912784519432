<template>
  <ValidationObserver v-slot="{ invalid }">
    <CForm ref="form" enctype="mutipart/form-data" :model="form" @submit.prevent="handleSubmit">
      <CRow>
        <CCol sm="6">
          <validation-provider rules="required" v-slot="{ errors }">
            <CInput
              class="is-invalid"
              label="ເລກ Serial :"
              prop="appl_serial"
              v-model="form.appl_serial"
            />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </validation-provider>
        </CCol>
        <CCol sm="6">
          <CInput label="Max address :" prop="appl_max_add" v-model="form.appl_max_add" />
        </CCol>
        <CCol sm="6">
          <CInput label="ຊື່ອຸປະກອນ :" prop="appl_name" v-model="form.appl_name" />
        </CCol>
        <CCol sm="12">
          <CTextarea label="ລາຍລະອຽດ :" prop="appl_detail" v-model="form.appl_detail" rows="5" />
        </CCol>
        <CCol sm="6">
          <CInput label="ຫົວໜ່ວຍ :" prop="appl_unit" v-model="form.appl_unit" />
        </CCol>
        <CCol sm="6">
          <validation-provider rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label>ປະເພດອຸປະກອນ :</label>
              <v-select
                class="is-invalid"
                prop="appl_g_id"
                v-model="form.appl_g_id"
                :options="applG"
                :reduce="appl_g_id => appl_g_id.appl_g_id"
                label="appl_g_name"
              />
              <span class="invalid-feedback">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </CCol>
        <CCol sm="6">
          <div class="form-group">
            <label for="file">ຮູບພາບ :</label>
            <input
              type="file"
              class="form-control-file"
              id="file"
              ref="file"
              @change="handleFileUpload"
              accept=".jpg"
            />
          </div>
        </CCol>
        <CCol sm="12">
          <CButton
            :disabled="invalid"
            type="submit"
            variant="outline"
            color="primary"
            class="float-right"
          >
            <CIcon name="cil-save" />ບັນທຶກ
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  </ValidationObserver>
</template>
 <script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { decrypt } from "@/libs/crypto";
export default {
  name: "ApplianceFormCreate",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    applG: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      form: {
        appl_serial: "",
        appl_max_add: "",
        appl_name: "",
        appl_detail: "",
        appl_unit: "",
        appl_g_id: "",
        file: null,
      },
    };
  },
  methods: {
    handleFileUpload() {
      let selectFile = this.$refs.file.files[0];

      let reader = new FileReader();

      // check size file
      if (selectFile["size"] < 1048576) {
        // check sign name

        let checkFile = selectFile.name
          .toString()
          .substring(selectFile.name.length - 3, selectFile.name.length);
        if (checkFile == "jpg" || checkFile == "JPG") {
          reader.onloadend = (selectFile) => {
            this.form.file = reader.result;
          };
          reader.readAsDataURL(selectFile);
        } else {
          this.$awn.alert("please select jpg file only");
        }
      } else {
        this.$awn.alert("please select file smaller than 1 MB");
      }
    },
    handleSubmit() {
      this.$emit("on_success", {
        appl_serial: this.form.appl_serial,
        appl_max_add: this.form.appl_max_add,
        appl_name: this.form.appl_name,
        appl_detail: this.form.appl_detail,
        appl_unit: this.form.appl_unit,
        appl_g_id: this.form.appl_g_id,
        file: this.form.file,
        appl_by: decrypt(this.$store.getters.getFullName),
      });
      this.form.appl_serial = "";
      this.form.appl_max_add = "";
      this.form.appl_name = "";
      this.form.appl_detail = "";
      this.form.appl_unit = "";
      this.form.appl_g_id = "";
      this.form.file = false;
    },
  },
};
</script>