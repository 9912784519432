<template>
  <div v-if="userGroup == 'administrator' || userGroup == 'manage'">
    <CCard>
      <CCardHeader>
        <strong>ຂໍ້ມູນອຸປະກອນ</strong>
        <CButton @click="modalCreate = true" variant="outline" color="primary" class="float-right">
          <CIcon name="cil-plus" />ເພີ້ມຂໍ້ມູນອຸປະກອນ
        </CButton>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CDataTable
              :items="items"
              :fields="fields"
              :loading="loading"
              hover
              sorter
              :table-filter="{placeholder: 'ຄົ້ນຫາ...', label: ' ', align: 'right'}"
              :items-per-page="20"
              pagination
            >
              <template #appl_pic="{item}">
                <td>
                  <CImg :src="getImagsePath(item.appl_pic)" height="40" />
                </td>
              </template>
              <template #actions="row">
                <td>
                  <CDropdown
                    size="sm"
                    toggler-text="ຈັດການ"
                    color="dark"
                    class="m-0 d-inline-block"
                  >
                    <CDropdownItem v-on:click="onClickUpdatePic(row.item)">ປ໋ຽນຮູບໃໝ່</CDropdownItem>
                    <CDropdownItem v-on:click="onClickUpdate(row.item)">ແກ້ໄຂ</CDropdownItem>
                    <CDropdownItem :disabled="userGroup != 'administrator'" v-on:click="onClickDelete(row.item)">ລົບ</CDropdownItem>
                  </CDropdown>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CModal
      title="ຟອມເພີ້ມຂໍ້ມູນອຸປະກອນ"
      size="lg"
      :show.sync="modalCreate"
      :closeOnBackdrop="false"
    >
      <applianceFormCreate :applG="applGData" @on_success="onSubmitCreate" />
      <template #footer>
        <div></div>
      </template>
    </CModal>

    <CModal
      title="ຟອມແກ້ໄຂຂໍ້ມູນຂໍ້ມູນອຸປະກອນ"
      size="lg"
      :show.sync="modalUpdate"
      :closeOnBackdrop="false"
    >
      <applianceFormUpdate :applG="applGData" :appl="applData" @on_success="onSubmitUpdate" />
      <template #footer>
        <div></div>
      </template>
    </CModal>

    <CModal title="ຟອມແກ້ໄຂຮູບພາບ" :show.sync="modalUpdatePic" :closeOnBackdrop="false">
      <applianceFormUploadPic :appl="applData" @on_success="onSubmitUpdatePic" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
  </div>
</template>

<script>
import {
  getApplianceAPI,
  createApplianceAPI,
  updateApplianceAPI,
  deleteApplianceAPI,
  uploadApplianceAPI,
} from "@/api/appliance";
import { getApplianceGroupAPI } from "@/api/applianceGroup";
import applianceFormCreate from "./components/ApplianceFormCreate";
import applianceFormUpdate from "./components/ApplianceFormUpdate";
import applianceFormUploadPic from "./components/ApplianceFormUploadPic";
import { decrypt } from "@/libs/crypto";
export default {
  name: "Appliance",
  components: {
    applianceFormCreate,
    applianceFormUpdate,
    applianceFormUploadPic,
  },
  data() {
    return {
      userGroup: decrypt(this.$store.getters.getUserGroup),
      loading: true,
      modalCreate: false,
      modalUpdate: false,
      modalUpdatePic: false,
      items: [],
      fields: [
        { key: "appl_pic", label: "ຮູບ" },
        { key: "appl_serial", label: "Serial" },
        { key: "appl_max_add", label: "Max Address" },
        { key: "appl_name", label: "ຊື່ອຸປະກອນ" },
        { key: "appl_total", label: "ຈໍານວນໃນສາງ" },
        { key: "appl_installed", label: "ຈໍານວນຕິດຕັ້ງແລ້ວ" },
        { key: "appl_broken", label: "ຈໍານວນເປເພ" },
        { key: "appl_g_name", label: "ປະເພດ" },
        { key: "actions", label: "ຈັດການ" },
      ],
      applGData: [],
      applData: {},
    };
  },
  mounted() {
    this.initialGetData();
  },
  methods: {
    // Load data
    initialGetData() {
      setTimeout(() => {
        this.loading = false;
      }, 5000);
      getApplianceAPI().then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
      getApplianceGroupAPI().then((response) => {
        this.applGData = response.data.data;
      });
    },

    getImagsePath(appl_pic) {
      return "/api_3c_link/uploaded/images_appliance/" + appl_pic;
    },

    // Create New
    onSubmitCreate(credentials) {
      createApplianceAPI(credentials)
        .then((response) => {
          this.$awn.success(response.data.message);
          this.initialGetData();
        })
        .catch((error) => {
          this.$awn.alert(error.response.data.message);
        });
      this.modalCreate = false;
    },
    // click Update Picture
    onClickUpdatePic(item) {
      this.applData = item;
      this.modalUpdatePic = true;
    },
    // function upload picture
    onSubmitUpdatePic(credentials) {
      uploadApplianceAPI(credentials)
        .then((response) => {
          this.$awn.success(response.data.message);
          this.initialGetData();
        })
        .catch((error) => {
          this.$awn.alert(error.response.data.message);
        });
      this.modalUpdatePic = false;
    },

    // Click Update button
    onClickUpdate(item) {
      this.applData = item;
      this.modalUpdate = true;
    },
    // function update
    onSubmitUpdate(credentials) {
      updateApplianceAPI(credentials)
        .then((response) => {
          this.$awn.success(response.data.message);
          this.initialGetData();
        })
        .catch((error) => {
          this.$awn.alert(error.response.data.message);
        });
      this.modalUpdate = false;
    },

    // Click delete button
    onClickDelete(item) {
      let onOk = () => {
        deleteApplianceAPI(item.appl_id)
          .then((response) => {
            this.$awn.success(response.data.message);
            this.initialGetData();
          })
          .catch((error) => {
            this.$awn.alert(error.response.data.message);
          });
      };
      this.$awn.confirm("ເຈົ້າຕ້ອງການລົບຂໍ້ມູນນີ້ແທ້ ຫຼີ ບໍ່ ?", onOk);
    },
  },
};
</script>
