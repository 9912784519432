<template>
    <CForm ref="form" enctype="mutipart/form-data" :model="form" @submit.prevent="handleSubmit">
      <CRow>
        <CCol sm="12">
            <div class="form-group">
              <label for="file">ຮູບພາບ :</label>
              <input
                class="form-control-file"
                type="file"
                id="file"
                ref="file"
                @change="handleFileUpload"
                accept=".jpg"
              />
            </div>
        </CCol>
        <CCol sm="12">
          <CButton
            type="submit"
            variant="outline"
            color="primary"
            class="float-right"
          >
            <CIcon name="cil-save" />ບັນທຶກ
          </CButton>
        </CCol>
      </CRow>
    </CForm>
</template>
 <script>
export default {
  name: "ApplianceFormUploadPic",
  props: {
    appl: {
      type: Object,
      required: true,
    },
  },
  computed: {
    form() {
      return {
        file: null,
      };
    },
  },
  methods: {
    handleFileUpload() {
      let selectFile = this.$refs.file.files[0];

      let reader = new FileReader();

      // check size file
      if (selectFile["size"] < 1048576) {
        // check sign name

        let checkFile = selectFile.name
          .toString()
          .substring(selectFile.name.length - 3, selectFile.name.length);
        if (checkFile == "jpg" || checkFile == "JPG") {
          reader.onloadend = (selectFile) => {
            this.form.file = reader.result;
          };
          reader.readAsDataURL(selectFile);
        } else {
          this.$awn.alert("please select jpg file only");
        }
      } else {
        this.$awn.alert("please select file smaller than 1 MB");
      }
    },
    handleSubmit() {
      this.$emit("on_success", {
        id: this.appl.appl_id,
        appl_serial: this.appl.appl_serial,
        file: this.form.file,
      });
    },
  },
};
</script>