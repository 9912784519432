<template>
  <ValidationObserver v-slot="{ invalid }">
    <CForm ref="form" enctype="mutipart/form-data" :model="form" @submit.prevent="handleSubmit">
      <CRow>
        <CCol sm="6">
          <validation-provider rules="required" v-slot="{ errors }">
            <CInput
              class="is-invalid"
              label="ເລກ Serial :"
              prop="appl_serial"
              v-model="form.appl_serial"
            />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </validation-provider>
        </CCol>
        <CCol sm="6">
          <CInput label="Max address :" prop="appl_max_add" v-model="form.appl_max_add" />
        </CCol>
        <CCol sm="6">
          <CInput label="ຊື່ອຸປະກອນ :" prop="appl_name" v-model="form.appl_name" />
        </CCol>
        <CCol sm="12">
          <CTextarea label="ລາຍລະອຽດ :" prop="appl_detail" v-model="form.appl_detail" rows="5" />
        </CCol>
        <CCol sm="6">
          <CInput label="ຫົວໜ່ວຍ :" prop="appl_unit" v-model="form.appl_unit" />
        </CCol>
        <CCol sm="6">
          <validation-provider rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label>ປະເພດອຸປະກອນ :</label>
              <v-select
                class="is-invalid"
                prop="appl_g_id"
                v-model="form.appl_g_id"
                :options="applG"
                :reduce="appl_g_id => appl_g_id.appl_g_id"
                label="appl_g_name"
              />
              <span class="invalid-feedback">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </CCol>
        <CCol sm="12">
          <CButton
            :disabled="invalid"
            type="submit"
            variant="outline"
            color="warning"
            class="float-right"
          >
            <CIcon name="cil-save" />ບັນທຶກ
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  </ValidationObserver>
</template>
 <script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { decrypt } from "@/libs/crypto";
export default {
  name: "ApplianceFormUpdate",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    appl: {
      type: Object,
      required: true,
    },
    applG: {
      type: Array,
      required: true,
    },
  },
  computed: {
    form() {
      return {
        appl_serial: this.appl.appl_serial,
        appl_max_add: this.appl.appl_max_add,
        appl_name: this.appl.appl_name,
        appl_detail: this.appl.appl_detail,
        appl_unit: this.appl.appl_unit,
        appl_g_id: this.appl.appl_g_id,
      };
    },
  },
  methods: {
    handleSubmit() {
      this.$emit("on_success", {
        id: this.appl.appl_id,
        appl_serial: this.form.appl_serial,
        appl_max_add: this.form.appl_max_add,
        appl_name: this.form.appl_name,
        appl_detail: this.form.appl_detail,
        appl_unit: this.form.appl_unit,
        appl_g_id: this.form.appl_g_id,
        appl_by: decrypt(this.$store.getters.getFullName),
      });
    },
  },
};
</script>